<template>
    <div class="subscribe-phone-number-view ViewWrapper">
        <div class="container">
            <div class="row" v-if="fetchingPortalInformation">
                <core-loader :active="true" :with-padding="true"></core-loader>
            </div>
            <div class="row" v-if="displayMain">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <!-- LOGO -->
                    <tenant-branding-logo></tenant-branding-logo>

                    <!-- BODY -->
                    <div class="gk-card no-border my-5 position-relative">

                        <!-- LOADER 2020-02-04 -->
                        <element-loading :active="subscribing" :background-color="'rgba(255, 255, 255, 0.9)'"></element-loading>

                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Phone Number Communication Subscription') }}</p>
                            <p class="mb-0 text-center weight-5 size-6" v-if="hasProbValidTranslation('branding.name')">{{ $t('branding.name') }}</p>
                        </div>

                        <form v-on:submit.prevent="submit" v-if="!subscriptionSucceed" data-vv-scope="subscribePhoneNumberForm">
                            <div class="form-group">
                                <label for="phoneNumber">{{ $t('Phone Number') }}</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">+</span>
                                    </div>
                                    <input id="phoneNumber" name="vv_phoneNumber" type="text" class="form-control" aria-describedby="phoneNumberHelp" :placeholder="$t('Enter phone number')" v-validate="'int_phone_validation|numeric|required|max:20'" v-model="formFields.phoneNumber">
                                </div>
                                <span class="small text-muted" v-if="phoneNumberCountryCode">Country: {{ phoneNumberCountryCode }} <br/></span>
                                <span class="small text-danger" v-show="errors.has('subscribePhoneNumberForm.vv_phoneNumber')">{{ $t('Phone_Number_Validation_1') }}</span>
                                <!--<span class="small text-danger">{{ errors.first('subscribePhoneNumberForm.vv_phoneNumber') }}</span>-->
                            </div>
                            <div class="form-group form-check">
                                <input id="oneToOneSms" name="oneToOneSms" type="checkbox" class="form-check-input" aria-describedby="oneToOneSmsHelp" v-model="formFields.oneToOneSms"/>
                                <label class="form-check-label" for="oneToOneSms">{{ $t('Type_Personal_Sms') }}</label>
                                <small id="oneToOneSmsHelp" class="form-text text-muted">{{ $t('Check to select this type of communication subscription') }}</small>
                            </div>
                            <div class="form-group form-check">
                                <input id="marketingSms" name="marketingSms" type="checkbox" class="form-check-input" aria-describedby="marketingSmsHelp" v-model="formFields.marketingSms"/>
                                <label class="form-check-label" for="marketingSms">{{ $t('Type_Marketing_Sms') }}</label>
                                <small id="marketingSmsHelp" class="form-text text-muted">{{ $t('Check to select this type of communication subscription') }}</small>
                            </div>
                            <div class="form-group form-check">
                                <input id="oneToOneCall" name="oneToOneCall" type="checkbox" class="form-check-input" aria-describedby="oneToOneCallHelp" v-model="formFields.oneToOneCall"/>
                                <label class="form-check-label" for="oneToOneCall">{{ $t('Type_Personal_Call') }}</label>
                                <small id="oneToOneCallHelp" class="form-text text-muted">{{ $t('Check to select this type of communication subscription') }}</small>
                            </div>
                            <div class="form-group form-check">
                                <input id="marketingCall" name="marketingCall" type="checkbox" class="form-check-input" aria-describedby="marketingCallHelp" v-model="formFields.marketingCall"/>
                                <label class="form-check-label" for="marketingCall">{{ $t('Type_Marketing_Call') }}</label>
                                <small id="marketingCallHelp" class="form-text text-muted">{{ $t('Check to select this type of communication subscription') }}</small>
                            </div>
                            <div v-if="action === 'subscribe'">
                                <button type="button" class="btn btn-primary" :disabled="disableSubmit" @click="submit">{{ $t('Subscribe') }}</button>
                            </div>
                            <div v-else>
                                <button type="button" class="btn btn-danger" :disabled="disableSubmit" @click="submit">{{ $t('Unsubscribe') }}</button>
                            </div>
                        </form>

                        <div class="gk-card text-center" v-if="subscriptionSucceed">
                            <p class="weight-3 size-5">{{ $t('Well done') }}</p>
                            <p class="weight-4 text-success mb-0" v-if="action === 'subscribe'">{{ $t('Your subscription has been submitted') }}</p>
                            <p class="weight-4 text-danger mb-0" v-else>{{ $t('Your subscription has been canceled') }}</p>
                        </div>
                    </div>

                    <tenant-box-footer :display-i18n-links="portalInformation.i18nAddOnEnabled"
                                       :display-cloutlayer-brand="portalInformation.productBranding"></tenant-box-footer>
                    <!-- This footer has the Cloutlayer's information. -->
                    <!--
                    <common-box-footer></common-box-footer>
                    -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PhoneNumberSubscriptionService } from '../common/services/api.service';
import PortalInformationAccessMixin from './Mixins/PortalInformationAccessMixin';
import TenantBoxFooter from './Snippets/TenantBoxFooter';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import TenantBrandingLogo from './Snippets/TenantBrandingLogo';

export default {
    name: 'SubscribePhoneNumberView',
    components: {
        TenantBrandingLogo,
        TenantBoxFooter
    },
    mixins: [
        PortalInformationAccessMixin('SubscribePhoneNumberView')
    ],
    data () {
        return {
            subscribing: false,
            subscriptionSucceed: false, // If true, means that the process ended and the user successfully subscribed.
            formFields: {
                phoneNumber: '',
                oneToOneSms: true,
                marketingSms: true,
                oneToOneCall: true,
                marketingCall: true
            }
        };
    },
    computed: {
        displayMain () {
            if (this.fetchingPortalInformation) {
                return false;
            }
            return this.portalInformation !== null;
        },
        displayForm () {
            return !this.subscriptionSucceed;
        },
        displaySuccessMessage () {
            if (this.subscribing) {
                return false;
            }
            return this.subscriptionSucceed;
        },
        disableSubmit () {
            return !this.formFields.oneToOneSms && !this.formFields.marketingSms && !this.formFields.oneToOneCall && !this.formFields.marketingCall;
        },
        phoneNumberCountryCode () {
            const phoneNumber = parsePhoneNumberFromString('+' + this.formFields.phoneNumber);
            if (phoneNumber) {
                return phoneNumber.country;
            } else {
                return null;
            }
        }
    },
    mounted () {
        this.explainRoute().then((result) => {
            if (!result.tenantFound) {
                // Tenant not found. Redirect to the front page.
                window.location.replace('/');
            }

            // If 'subscribe' set the checkboxes to true, else ('subscribe') set them to false.
            if (this.action === 'subscribe') {
                this.formFields.oneToOneSms = true;
                this.formFields.marketingSms = true;
                this.formFields.oneToOneCall = true;
                this.formFields.marketingCall = true;
            } else {
                this.formFields.oneToOneSms = false;
                this.formFields.marketingSms = false;
                this.formFields.oneToOneCall = false;
                this.formFields.marketingCall = false;
            }
        });
    },
    methods: {
        async submit () {
            // prevent duplicate.
            // @future : add to all forms in all projects?
            if (this.subscribing) {
                return;
            }

            // Validate the form.
            const result = await this.$validator.validateAll('subscribePhoneNumberForm').then(valid => {
                return Promise.resolve({
                    valid: valid
                });
            });

            // If not valid, do nothing.
            if (!result.valid) {
                return;
            }

            // Start loader.
            this.subscribing = true;

            // Make the requests.
            // let result1 = null;
            // let result2 = null;
            const dataObj = {
                'phoneNumber': this.formFields.phoneNumber,
                'phoneNumberIdentityProfileId': null,
                'status': null,
                'withNotification': false,
                'withConfirmation': false,
                'withDataProcessingConsent': true, // @future Add checkbox!
                'types': [],
                'tenantId': this.tenantId
            };

            // Add the '+' character.
            dataObj.phoneNumber = '+' + dataObj.phoneNumber;

            // Add types.
            if (this.formFields.oneToOneSms) {
                dataObj.types.push('ONE_TO_ONE_SMS');
            }
            if (this.formFields.marketingSms) {
                dataObj.types.push('MARKETING_SMS');
            }
            if (this.formFields.oneToOneCall) {
                dataObj.types.push('ONE_TO_ONE_CALL');
            }
            if (this.formFields.marketingCall) {
                dataObj.types.push('MARKETING_CALL');
            }

            // Add status. It is impossible for action to have a value which is not equal to 'subscribe' or 'unsubscribe'.
            if (this.action === 'subscribe') {
                dataObj.status = 'OPTED_IN';
            } else if (this.action === 'unsubscribe') {
                dataObj.status = 'OPTED_OUT';
            }

            await PhoneNumberSubscriptionService.subscribe(dataObj).then(({ data }) => {
                return Promise.resolve({
                    succeed: true,
                    data: data,
                    error: null
                });
            }).catch((reason) => {
                return Promise.resolve({
                    succeed: false,
                    data: null,
                    error: reason
                });
            });

            // We do not check the results. We except that both requests succeed.
            // The backend implementation if ultra safe if the phoneNumber is valid.
            // @future : Implement a more reliable mechanism.

            // Stop loader.
            this.subscribing = false;

            // Set confirmation to succeed.
            this.subscriptionSucceed = true;
        }
    }
};
</script>
